import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./components/Login";
import ResultPage from "./AssessmentComponents/PageComponents/ResultPage";
import Portal from "./AssessmentComponents/Portal/Portal";
import AdminPage from "./Admin/AdminPageComponents/AdminPage";
import Assesments from "./Admin/AdminPageComponents/Assesments";
import Reports from "./Admin/AdminPageComponents/Reports";
import ExamInstructions from "./components/ExamInstructions";
import Container from "./components/Container";
import { Result, Button } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import './Styles/Responsive.css'
import './App.css'; 
import Landing from "./LandingPageComponents/src/Pages/Home";
import Landingpage from "./components/LandingPage/LandingPage";
const App = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android|ipad|playbook|silk|blackberry|bb|iemobile|opera mini|mobile|kindle|silk-accelerated|phone|tablet/i.test(userAgent.toLowerCase())) {
      setIsMobile(false);
    }
  }, []);

  if (isMobile) {
    return <div className="mobile-warning">No Access For Mobile Devices</div>;
  }

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landingpage />} />
          <Route path="/result" element={<ResultPage />} />
          <Route path="/:assessmentname/:assessmentId/:collegeId/instructions/:assessmentname/:assessmentId/assessment" element={<Portal />} />
          <Route path="/vjthalearning/:assessmentId/:collegeId/:password" element={<Login />} />
          {/* <Route path="/vjthalearning/:assessmentId/:collegeId/" element={<Login />} /> */}
          <Route path="/:assessmentname/:assessmentId/:collegeId/instructions" element={<ExamInstructions />} />
          <Route path="/submittest" element={<Container />} />
          <Route path="/vjtha-admin/@admin-vjtha" element={<AdminPage />} />
          <Route path="/vjtha-drive/VjthaASXXXX" element = {<Result
      status="success"
      title="You Test is Successfully Submitted!"
      subTitle="We appreciate your participation and best of luck !!."
      icon={<CheckCircleOutlined style={{ color: '#52c41a', fontSize: '48px' }} />}
    />}/>
          {/* <Route path="/vts-drive/VTSASXXXX" element = {<h2 style={{margin:"20px auto",textAlign:"center"}}>Thanks For the Feedback</h2>}/> */}
          <Route path="/admin/assessmentdetails/:assessmentId" element={<Assesments />} />
          <Route path="/admin/assessmentreports/:assessmentId" element={<Reports />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
